import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userLoggedOut } from "../auth/authSlice";
import axios from "axios";

const customBaseQuery = async (args, api) => {
  const { url, body } = args;

  // if (url === "/api/campaigns/send-mail") {
  //   return new Promise(async (resolve, reject) => {
  //     const token = api.getState()?.auth?.accessToken;
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_API_URL}/api/campaigns/send-mail`,
  //       body,

  //       {
  //         headers: {
  //           Accept: "text/plain",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const stream = response.data;
  //     const data = {};
  //     console.log(response);
  //     // stream.on("data", (chunk) => {
  //     //   data = JSON.parse(chunk.toString());
  //     // });
  //     // stream.on("end", () => {
  //     //   resolve({ data });
  //     // });
  //     // stream.on("error", reject);
  //   });
  // } else {
  return fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState, endpoint }) => {
      const token = getState()?.auth?.accessToken;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  })(args, api);
};
// };

// create api slice
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: customBaseQuery,
  tagTypes: [
    "getCreditCards",
    "getCreditCard",
    "getCampaigns",
    "getNotices",
    "getNotice",
    "getDashboardInfo",
  ],

  endpoints: (builder) => ({}),
});
