import React, { createRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function Call() {
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");
  const buttonRef = createRef(null);
  const link = `tel: ${phone}`;

  useEffect(() => {
    buttonRef.current.click();
  }, []);
  return (
    <div className="p-4" ref={buttonRef}>
      <a id="callbutton" className="underline text-blue-600" href={link}>
        If call app didn't open automatically, click here
      </a>
    </div>
  );
}

export default Call;
